@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

*{
    margin: 0;
    padding: 0;
    font-family: 'Space Grotesk', sans-serif;
    box-sizing: border-box;
}

.App{
    max-width: 1920px;
    margin: 0 auto;
    padding: 16px 4px 0 4px;
}


/* * container */
.container {
    max-width: 1440px;
  
    margin: 0 auto !important;
}




@media only screen and (max-width: 992px) {
    .container {
        padding: 0 50px;
    }
}

@media only screen and (max-width: 768px) {
    .container {
       padding: 0 20px;
    }
}

@media only screen and (max-width: 576px) {
    .container {
        padding: 0 10px;
    }

}

@media only screen and (max-width: 456px) {
    .container {
        padding: 0 10px;
    }
}

/* ------------------------------------------------ */

.hero_section{
    max-height: 788px;
}

@media only screen and (min-width: 768px) {
    .hero_section{
        height: 80vh;
    }
    .h1_container{
        height: 840px;
    }
    .h2_container{
        height: 560px;
    }
}
.slick-arrow{
    display: none!important;
}
.slick-dots{
    width: 100%;
    position: absolute;
    top: 0px; 
    right: 20px;
    z-index: 100;
    text-align: right!important;
}
.slick-slider{
    width: 100%;
    position: relative;
}
.slick-dots li.slick-active button:before{
    color: white!important;
}
body .slick-dots li button{
    width: 100%;
    padding: 2rem 0;
}
body .slick-dots li button:before{
    top: auto!important;
    color: white!important;
}
.slick-dots li button:before{
    font-size: 12px!important;
    content: ""!important;
    background: white!important;
    width: 100px!important;
    height: 3px!important;
    margin-right: 30px!important;
}



.slick-dots li{
    width: 109px!important;
    height: 0px!important;
}
ul{
   list-style-image: url("/public/images/radiob.png");
   
    
}
li{
    padding-left: 10px;
}

.rounded_px{
    border-radius: 4px;
}


@media only screen and (max-width: 500px) {
    .slick-dots li button:before{
        font-size: 12px!important;
        content: ""!important;
        background: white!important;
        width: 40px!important;
        height: 3px!important;
        margin-right: 10px!important;
    }
    
    .slick-dots li button:before{
        font-size: 12px!important;
        content: ""!important;
        background: white!important;
        width: 40px!important;
        height: 3px!important;
        margin-right: 10px!important;
    }
    .slick-dots li{
        width: 49px!important;
        height: 0px!important;
    }
  }

  body.scrolling .scrollToSee{
    opacity: 0;
  }

  .scrollDown{
    left: 0;
    position: absolute;
    animation: jumpInfinite 1.5s infinite;
  }

  .wall-shadow{
    filter: drop-shadow(32px 32px 96px rgba(5, 36, 66, 0.3));
  }

  @keyframes jumpInfinite {
    0% {
        margin-top: 0px;
    }
    50% {
        margin-top: 10px;
    }
    100% {
        margin-top: 0px;
    }
  }

#productNAV{
    height:0;
    overflow: hidden;
}

  @media (min-width:992px) {
    #productNAV.shown{
        height: auto;
    }

    #productNAV:not(.shown){
    transition:height .2s ease-out;
    height:0;
    overflow: hidden;
    }

    #productNAV:not(.shown) .productNavInner{
        max-width: 1440px;
        margin: 0 auto;
        overflow: hidden;
    }

    #productNAV.shown{
        margin: 1rem auto 4rem auto !important;
    }
    #productNAV.shown .productNavInner{
        margin: 0 auto;
        max-width: 1440px;
        transition: all .5s ease;
    }
}

.list h1{
    font-weight: 500;
    font-size: 2.25rem;
    line-height: 2.5rem;
    margin-bottom: 1.5rem;
}

.list h2{
    font-weight: 500;
    font-size: 2rem;
    line-height: 2.25rem;
    margin-bottom: 1.25rem;
}

.wp-block-image .alignright{
    float:right;
    padding-left: 1rem;
    margin-bottom: 1rem;
}

.list ul li{
    margin-bottom: 8px;
}

.stickyPadding{
    /* transition: all 0.3s ease; */
    /* padding: 2rem 0; */
}

.scrolling .stickyPadding{
    /* padding: 1rem 0; */
}
.scrolling .header{
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.15);
}

.productNavInner.see{
    transform: scale(1.1);
}

.content p{
    margin-bottom: 1rem;
}
.content a{
    text-decoration: underline;
}

.content h2{
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 2.5rem;
    margin-bottom: 1.5rem;
    margin-top: 1rem;
}

.carousel__button.is-prev, .carousel__button.is-next{
    height: 100%;
    width: 40%;
}

.carousel__button.is-prev{
    justify-content: left;
    margin-left: 2rem;
}
.carousel__button.is-next{
    justify-content: right;
    margin-right: 2rem;
}